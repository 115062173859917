import React from 'react';

function Footer() {
    return (
        <footer className="myfooter">
            Work by Isabel &copy; 2024
        </footer>
    );
}

export default Footer;
